.video-background {
    background: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -99;
    width: 100%;
    height: 100%;
}
.video-foreground,
.video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0.65;
}
#vidtop-content {
    top: 0;
    color: #fff;
}

@media (min-aspect-ratio: 16/9) {
    .video-foreground {
        height: 300%;
        top: -100%;
    }
}
@media (max-aspect-ratio: 16/9) {
    .video-foreground {
        width: 300%;
        left: -100%;
    }
}
@media all and (max-width: 600px) {
    .vid-info {
        width: 50%;
        padding: 0.5rem;
    }
    .vid-info h1 {
        margin-bottom: 0.2rem;
    }
}
@media all and (max-width: 500px) {
    .vid-info .acronym {
        display: none;
    }
}
