@import url(https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300;400&&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300;700&display=swap);
/* @import url("https://fonts.googleapis.com/css2?family=Petit+Formal+Script&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap"); */

.video-background {
    background: #000;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -99;
    width: 100%;
    height: 100%;
}
.video-foreground,
.video-background iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    opacity: 0.65;
}
#vidtop-content {
    top: 0;
    color: #fff;
}

@media (min-aspect-ratio: 16/9) {
    .video-foreground {
        height: 300%;
        top: -100%;
    }
}
@media (max-aspect-ratio: 16/9) {
    .video-foreground {
        width: 300%;
        left: -100%;
    }
}
@media all and (max-width: 600px) {
    .vid-info {
        width: 50%;
        padding: 0.5rem;
    }
    .vid-info h1 {
        margin-bottom: 0.2rem;
    }
}
@media all and (max-width: 500px) {
    .vid-info .acronym {
        display: none;
    }
}

.show{transition:all 1000ms linear;opacity:1}.hide{opacity:0;transition:all 1000ms linear}

*{box-sizing:border-box}.Toggle{align-items:center;border:1px solid #ffffff;border-radius:3rem;color:#fff;display:flex;height:calc(3rem + 1.5rem);justify-content:space-around;padding:0.5rem;position:relative;transition:all 0.2s ease-out;width:8rem}.Toggle--checked{background-color:#00000000;border-color:#ffffff}.Toggle__slider{border:2px solid #dbdbdb;border-radius:50%;content:"";left:.5rem;height:3rem;width:3rem;position:absolute;transform:translateX(0);transition:all 0.2s ease-out}.Toggle__text{text-align:center;width:50%}.Toggle input:checked+.Toggle__slider{border:2px solid #dbdbdb;transform:translateX(calc(8rem - 3rem - 1.5rem))}.Toggle input[type="checkbox"]{display:none}

